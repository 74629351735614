import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"

function Programs() {
    return (
        <div>
            <Helmet>
    <title>Training Programs & Exercise Guides | Kinetic Impact</title>
    <meta property="og:title" content="Training Programs & Exercise Guides | Kinetic Impact" />
    <meta property="og:image" content={OpenGraphLogo} />
    <meta property="og:description" content="Access professional training programs and exercise guides for home workouts, sport-specific training, injury rehabilitation, and maintenance routines." />
    <meta name="description" content="Download expert-designed training programs including home exercise guides, sport-specific training protocols, rehabilitation programs, and maintenance routines." />
    <meta name="keywords" content="training programs, home exercise programs, sport-specific training, rehabilitation guides, exercise routines, maintenance workouts, physical therapy programs" />
</Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/programs"} 
                  header={"Expert Training Programs to Support Your Journey"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Access professional training programs designed by our expert team. Whether you're recovering from an injury, training for a specific sport, or maintaining your fitness, our comprehensive guides provide the structure and guidance you need.
                    </p>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Each program includes detailed exercise descriptions, progression guidelines, and tips for optimal form and technique.
                    </p>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Available Programs</h2>
                    
                    {/* Current "Coming Soon" Display */}
                    <div className={"flex flex-col justify-center items-center py-12 max-w-5xl mx-auto"}>
                        <h3 className={"text-3xl text-center mb-4"}>Coming Soon!</h3>
                        <p className={"text-center max-w-2xl"}>
                            We're currently developing our library of specialized training programs. 
                            Check back soon for comprehensive guides covering:
                        </p>
                        <ul className={"list-none text-center mt-6"}>
                            <li className={"mb-3"}>Home Exercise Programs</li>
                            <li className={"mb-3"}>Sport-Specific Training Guides</li>
                            <li className={"mb-3"}>Post-Injury Rehabilitation Protocols</li>
                            <li className={"mb-3"}>Maintenance Exercise Routines</li>
                        </ul>
                    </div>

                    {/* 
                    // Future Program Display Example (Commented Out)
                    // When adding programs, uncomment and modify this structure
                    
                    <div className={"grid lg:grid-cols-2 gap-8 max-w-5xl mx-auto"}>
                        <div className={"bg-gray-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"}>
                            <h3 className={"text-2xl font-bold mb-4"}>Home Exercise Fundamentals</h3>
                            <p className={"mb-4"}>A comprehensive guide to building strength and mobility from home with minimal equipment.</p>
                            <ul className={"mb-4"}>
                                <li>• 8-week progressive program</li>
                                <li>• Equipment-free options</li>
                                <li>• Form guidance videos</li>
                                <li>• Progress tracking sheets</li>
                            </ul>
                            <a 
                                href="/programs/home-exercise-fundamentals.pdf" 
                                className={"inline-block bg-accent hover:brightness-75 px-6 py-2 rounded text-center w-full"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download Program (PDF)
                            </a>
                        </div>

                        <div className={"bg-gray-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"}>
                            <h3 className={"text-2xl font-bold mb-4"}>Runner's Recovery Guide</h3>
                            <p className={"mb-4"}>Essential exercises and protocols for preventing and recovering from common running injuries.</p>
                            <ul className={"mb-4"}>
                                <li>• Pre/post run routines</li>
                                <li>• Injury prevention exercises</li>
                                <li>• Recovery protocols</li>
                                <li>• Return-to-run progressions</li>
                            </ul>
                            <a 
                                href="/programs/runners-recovery-guide.pdf" 
                                className={"inline-block bg-accent hover:brightness-75 px-6 py-2 rounded text-center w-full"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download Program (PDF)
                            </a>
                        </div>

                        <div className={"bg-gray-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"}>
                            <h3 className={"text-2xl font-bold mb-4"}>Post-Surgery ACL Protocol</h3>
                            <p className={"mb-4"}>Comprehensive rehabilitation guide for ACL reconstruction recovery, from early healing to return to sport.</p>
                            <ul className={"mb-4"}>
                                <li>• Phase-by-phase progression</li>
                                <li>• Weekly exercise protocols</li>
                                <li>• Milestone checkpoints</li>
                                <li>• Return to sport criteria</li>
                            </ul>
                            <a 
                                href="/programs/acl-protocol.pdf" 
                                className={"inline-block bg-accent hover:brightness-75 px-6 py-2 rounded text-center w-full"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download Program (PDF)
                            </a>
                        </div>

                        <div className={"bg-gray-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow"}>
                            <h3 className={"text-2xl font-bold mb-4"}>Maintenance Mobility Routine</h3>
                            <p className={"mb-4"}>Daily mobility exercises to maintain joint health and prevent injuries for active individuals.</p>
                            <ul className={"mb-4"}>
                                <li>• Morning and evening routines</li>
                                <li>• Target area focus sessions</li>
                                <li>• Time-efficient options</li>
                                <li>• Progressive variations</li>
                            </ul>
                            <a 
                                href="/programs/mobility-routine.pdf" 
                                className={"inline-block bg-accent hover:brightness-75 px-6 py-2 rounded text-center w-full"}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Download Program (PDF)
                            </a>
                        </div>
                    </div>
                    */}

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Why Use Our Programs?</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Expert-Designed Content</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Every program is created by our team of experienced physical therapists and movement specialists, incorporating evidence-based practices and proven rehabilitation techniques.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Clear Progression Paths</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Our programs include detailed progression guidelines to help you advance safely and effectively through your training or rehabilitation journey.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Comprehensive Guidance</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Each program includes detailed exercise descriptions, form cues, and tips to ensure you're performing movements correctly and safely.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Flexible Application</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Whether you're working with us directly or following the programs independently, our guides are designed to support your success with clear, actionable instructions.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/contact"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Contact Us For More Information
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default Programs